import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithHistory = ({ children }: any) => {
  const navigate = useNavigate();

  const domain = "dev-gpl1ubw9.us.auth0.com";
  const clientId = "OgsQtpR8fPaxB3dT7nOgRyU1Sb0q5U15";
  const redirectUri = window.location.protocol + "//" + window.location.host + "/callback";

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      audience={"http://ec2-54-75-49-5.eu-west-1.compute.amazonaws.com/"}
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
