"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddCertificate = exports.CreateTransfer = exports.CreateCylinder = exports.UpdateCylinder = exports.GetAllCylindersDummy = exports.GetAllCylinders = exports.GetAllCylinderCertificates = exports.GetAllCylinderTransactions = exports.BASE_URL = exports.GasTypeEnum = exports.GasGroupEnum = exports.CylinderStatusEnum = void 0;
var CylinderStatusEnum;
(function (CylinderStatusEnum) {
    CylinderStatusEnum["EMPTY"] = "Empty";
    CylinderStatusEnum["FILLED"] = "Filled";
    CylinderStatusEnum["SCRAPPED"] = "Scrapped";
})(CylinderStatusEnum = exports.CylinderStatusEnum || (exports.CylinderStatusEnum = {}));
var GasGroupEnum;
(function (GasGroupEnum) {
    GasGroupEnum["GAS"] = "gas";
    GasGroupEnum["REFRIGERANT"] = "Refrigerant";
    GasGroupEnum["SPANGAS"] = "Scrapped";
})(GasGroupEnum = exports.GasGroupEnum || (exports.GasGroupEnum = {}));
var GasTypeEnum;
(function (GasTypeEnum) {
    GasTypeEnum["N10FG"] = "Nitrogen Food Grade";
    GasTypeEnum["E10FG"] = "Argon Food Grade";
    GasTypeEnum["N50"] = "Nitrogen 5.0 Grade";
    GasTypeEnum["N30"] = "Nitrogen 3.0 Grade";
    GasTypeEnum["O"] = "Oxygen";
    GasTypeEnum["A"] = "Acetylene";
    GasTypeEnum["E10"] = "Argon";
    GasTypeEnum["M01"] = "Unimix 10";
    GasTypeEnum["M02"] = "Unimix 20";
    GasTypeEnum["C"] = "Carbon Dioxide";
    GasTypeEnum["H"] = "Helium";
    GasTypeEnum["H50"] = "Helium 5.0 Grade";
})(GasTypeEnum = exports.GasTypeEnum || (exports.GasTypeEnum = {}));
// Function to simulate loading times for dummy data
var sleep = function (ms) { return new Promise(function (r) { return setTimeout(r, ms); }); };
exports.BASE_URL = "https://api.pharoxblockchain.com";
var ALL_CYLINDERS_PATH = "/v1/cylinders";
var UPDATE_CYLINDER_PATH = "/v1/cylinder";
var CREATE_CYLINDER_PATH = "/v1/cylinder";
var CREATE_TRANSFER_PATH = "/v1/cylinder/transfer/";
var ALL_CYLINDER_TRANSACTIONS_PATH = "/v1/transactions/";
var ADD_CERTIFICATE_PATH = "/v1/certificate/upload/";
var GET_CERTIFICATE_PATH = "/v1/certificate/";
function getAuthString(token) {
    return "Bearer ".concat(token);
}
function GetAllCylinderTransactions(_a) {
    var token = _a.token, cylinderID = _a.cylinderID;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + ALL_CYLINDER_TRANSACTIONS_PATH + cylinderID, {
                        headers: {
                            'Authorization': getAuthString(token)
                        }
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.GetAllCylinderTransactions = GetAllCylinderTransactions;
function GetAllCylinderCertificates(_a) {
    var token = _a.token, cylinderID = _a.cylinderID;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + GET_CERTIFICATE_PATH + cylinderID, {
                        headers: { 'Authorization': getAuthString(token) },
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.GetAllCylinderCertificates = GetAllCylinderCertificates;
function GetAllCylinders(_a) {
    var token = _a.token;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + ALL_CYLINDERS_PATH, {
                        headers: {
                            'Authorization': getAuthString(token)
                        }
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.GetAllCylinders = GetAllCylinders;
function GetAllCylindersDummy() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        results: [
                            {
                                CylinderID: "ccce77d9-000c-436b-a738-fa86cbb5f7f4",
                                SiNo: "123456",
                                Barcode: "789546",
                                PartNumber: "CYL-5 Line",
                                Description: "This cylinder has a slight mark on the neck",
                                Status: CylinderStatusEnum.FILLED,
                                GasType: GasTypeEnum.N10FG,
                                Size: 50,
                                Owner: "google-oauth2|113599735198583454401",
                                ProductionDate: "2022-12-23T09:40:11Z",
                                Value: "$50",
                                Manufacturer: "UNITOR"
                            },
                            {
                                CylinderID: "1aaf24bd-12f8-4756-8ae1-e031d4451343",
                                SiNo: "887654",
                                Barcode: "945123",
                                PartNumber: "CYL-6 Line",
                                Description: "",
                                Status: CylinderStatusEnum.EMPTY,
                                GasType: GasTypeEnum.N10FG,
                                Size: 50,
                                Owner: "google-oauth2|108465971203048175624",
                                ProductionDate: "2021-10-20T016:20:00Z",
                                Value: "$50",
                                Manufacturer: "UNITOR"
                            }
                        ]
                    };
                    return [4 /*yield*/, sleep(2000)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.GetAllCylindersDummy = GetAllCylindersDummy;
function UpdateCylinder(_a) {
    var token = _a.token, cylinder = _a.cylinder;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + UPDATE_CYLINDER_PATH, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json', 'Authorization': getAuthString(token) },
                        body: JSON.stringify(cylinder),
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.UpdateCylinder = UpdateCylinder;
function CreateCylinder(_a) {
    var token = _a.token, cylinder = _a.cylinder;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + CREATE_CYLINDER_PATH, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': getAuthString(token) },
                        body: JSON.stringify(cylinder),
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.CreateCylinder = CreateCylinder;
function CreateTransfer(_a) {
    var token = _a.token, input = _a.input, newOwnerID = _a.newOwnerID;
    return __awaiter(this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(exports.BASE_URL + CREATE_TRANSFER_PATH + newOwnerID, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': getAuthString(token) },
                        body: JSON.stringify(input),
                    })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.CreateTransfer = CreateTransfer;
function AddCertificate(_a) {
    var token = _a.token, file = _a.file, cylinderID = _a.cylinderID;
    return __awaiter(this, void 0, void 0, function () {
        var formData, response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    formData = new FormData();
                    formData.append('file', file);
                    return [4 /*yield*/, fetch(exports.BASE_URL + ADD_CERTIFICATE_PATH + cylinderID, {
                            method: 'POST',
                            body: formData,
                            headers: { 'Authorization': getAuthString(token) },
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    return [2 /*return*/, data];
            }
        });
    });
}
exports.AddCertificate = AddCertificate;
