import DashboardBarIndicator from '../components/dashboardBarIndicator';
import { Cylinder, GasTypeEnum, GetAllCylinders } from '@pharox/api-package/lib';
import Stack from '@mui/material/Stack';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const DashboardBar = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { isLoading, data } = useQuery({
        queryKey: 'cylinders', 
        queryFn: async () => {
            var token = await getAccessTokenSilently();
            var result = await GetAllCylinders({token: token});
            return result;
        },
    });

    if (isLoading){
        <Stack direction="row" spacing={2}>
        </Stack>
    }

    return (
        <Stack direction="row" spacing={2}>
                <DashboardBarIndicator loading={isLoading} total={data?.results.length ?? 0} head1={"Total Cylinders"}></DashboardBarIndicator>
                <DashboardBarIndicator loading={isLoading} total={data?.results.filter((cylinder) => cylinder.Status === "Empty").length ?? 0} head1={"Cylinders empty"} ></DashboardBarIndicator>
                <DashboardBarIndicator loading={isLoading} total={data?.results.filter((cylinder) => cylinder.Status === "Filled").length ?? 0}  head1={"Cylinders filled"} ></DashboardBarIndicator>           
                
                {
                    Object.entries(GasTypeEnum)
                    .map(([name, type]) => {
                        var amountOfCylinders = data?.results.filter((cylinder) => cylinder.GasType === type).length ?? 0;
                        
                        if(amountOfCylinders === 0){
                            return undefined;
                        }

                        return (
                            <DashboardBarIndicator 
                                total={amountOfCylinders}
                                head1={type.toString()}
                                key={type.toString()}
                                loading={isLoading}
                            ></DashboardBarIndicator>
                        )
                    })
                }
            </Stack>
    );
}
