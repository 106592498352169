import React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./passportModal.css";
import { CreateTransfer, Cylinder, CylinderStatusEnum, GasTypeEnum, UpdateCylinder } from '@pharox/api-package/lib';
import { List, ListItem, TextField, Dialog, Typography } from '@mui/material';
import ActionModal from '../ActionModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useMutation } from 'react-query';
import StatusSelect from '../StatusSelect';
import GasTypeSelect from '../GasTypeSelect';

interface IPassportModal {
    setOpen: (x: any) => void;
    cylinder: Cylinder;
}

const PassportModal: React.FC<IPassportModal> = ({
    setOpen,
    cylinder
}) => {
    const [newCylinder, setNewCylinder] = React.useState(cylinder);

    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const { mutateAsync, data, isSuccess, error, isError, isLoading} = useMutation(UpdateCylinder, {
        onSuccess: () => {
            queryClient.invalidateQueries('cylinders')
        },
    });

    const handleClick = async () => {
        const token = await getAccessTokenSilently();
        await mutateAsync({
            token: token,
            cylinder: newCylinder
        });
    }


    return (
        <ActionModal
            setOpen={setOpen}
            handleClick={handleClick}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error?.toString() ?? "Unknown"}
            success={data?.message ?? "Success"}
            actionText={"Send"}
            showAction={!isSuccess}
        >
                <Typography sx={{textAlign: "center"}} variant="h4">Passport</Typography>
                    <TextField disabled value={newCylinder.CylinderID} label="CylinderID" variant="outlined"></TextField>
                    <TextField disabled value={newCylinder.SiNo} label="CylinderNumber" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Barcode: e.target.value})} value={newCylinder.Barcode} label="Barcode" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, PartNumber: e.target.value})}value={newCylinder.PartNumber} label="PartNumber" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Description: e.target.value})}value={newCylinder.Description} label="Description" variant="outlined"></TextField>
                    <StatusSelect onChange={(e) => setNewCylinder({...newCylinder, Status: e.target.value as CylinderStatusEnum})} label={"Status"} value={newCylinder.Status}/>
                    <GasTypeSelect onChange={(e) => setNewCylinder({...newCylinder, GasType: e.target.value as GasTypeEnum})} label={"Gas Type"} value={newCylinder.GasType}/>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Size: Number.parseInt(e.target.value)})} value={newCylinder.Size} label="Size" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Owner: e.target.value})} disabled value={newCylinder.Owner} label="Owner" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, ProductionDate: e.target.value})} disabled value={newCylinder.ProductionDate} label="Production Date" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Value: e.target.value})} value={newCylinder.Value} label="Value" variant="outlined"></TextField>
                    <TextField contentEditable={false} value={newCylinder.Manufacturer} label="Manufacturer" variant="outlined"></TextField>
        </ActionModal>
    );
}

export default PassportModal;
