import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/button";
import LoginButton from "../components/loginButton";
import pharoxLogoPath from '../images/pharoxlogo.png';
import { Box, Dialog, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import { padding } from "@mui/system";

// import { withPageAuthRequired } from '@auth0/nextjs-auth0';

// This page is only shown when not logged in yet
const IndexPage = () => {
  return (
    <>
      <Dialog onClose={() => {}} open={true} fullWidth>
        <DialogTitle>Login</DialogTitle>
        <Box sx={{p: 4, w: '100%'}}>
          <LoginButton></LoginButton>
        </Box>
      </Dialog>  
    </>
  )
}

export default IndexPage;

// export const getServerSideProps = withPageAuthRequired();
