import React from 'react';
import { CreateCylinder, CreateTransfer, Cylinder, CylinderStatusEnum, GasTypeEnum, UpdateCylinder } from '@pharox/api-package/lib';
import { List, ListItem, TextField, Dialog, Typography } from '@mui/material';
import ActionModal from './ActionModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useMutation } from 'react-query';
import StatusSelect from './StatusSelect';
import GasTypeSelect from './GasTypeSelect';

interface ICreateCylinderModal {
    setOpen: (x: any) => void;
}

const CreateCylinderModal: React.FC<ICreateCylinderModal> = ({
    setOpen
}) => {
    const [newCylinder, setNewCylinder] = React.useState<Cylinder>({
        CylinderID: '',
        SiNo: '',
        Barcode: '',
        PartNumber: '',
        Description: '',
        Status: CylinderStatusEnum.EMPTY,
        GasType: GasTypeEnum.E10FG,
        Size: 0,
        Owner: '',
        ProductionDate: '',
        Value: '',
        Manufacturer: ''
    });

    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const { mutateAsync, data, isSuccess, error, isError, isLoading} = useMutation(CreateCylinder, {
        onSuccess: () => {
            queryClient.invalidateQueries('cylinders')
        },
    });

    const handleClick = async () => {
        const token = await getAccessTokenSilently();
        await mutateAsync({
            token: token,
            cylinder: newCylinder
        });
    }


    return (
        <ActionModal
            setOpen={setOpen}
            handleClick={handleClick}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error?.toString() ?? "Unknown"}
            success={data?.message ?? "Success"}
            actionText={"Create"}
            showAction={!isSuccess}
        >
                <Typography sx={{textAlign: "center"}} variant="h4">Add Cylinder</Typography>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, SiNo: e.target.value})} value={newCylinder.SiNo} label="CylinderNumber" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Barcode: e.target.value})} value={newCylinder.Barcode} label="Barcode" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, PartNumber: e.target.value})}value={newCylinder.PartNumber} label="PartNumber" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Description: e.target.value})}value={newCylinder.Description} label="Description" variant="outlined"></TextField>
                    <StatusSelect onChange={(e) => setNewCylinder({...newCylinder, Status: e.target.value as CylinderStatusEnum})} label={"Status"} value={newCylinder.Status}/>
                    <GasTypeSelect onChange={(e) => setNewCylinder({...newCylinder, GasType: e.target.value as GasTypeEnum})} label={"Gas Type"} value={newCylinder.GasType}/>
                    <TextField type="number" onChange={(e) => setNewCylinder({...newCylinder, Size: Number.parseInt(e.target.value)})} value={newCylinder.Size} label="Size" variant="outlined"></TextField>
                    <TextField InputLabelProps={{ shrink: true }} type="date" onChange={(e) => setNewCylinder({...newCylinder, ProductionDate: e.target.value})} value={newCylinder.ProductionDate} label="Production Date" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Value: e.target.value})} value={newCylinder.Value} label="Value" variant="outlined"></TextField>
                    <TextField onChange={(e) => setNewCylinder({...newCylinder, Manufacturer: e.target.value})} value={newCylinder.Manufacturer} label="Manufacturer" variant="outlined"></TextField>
        </ActionModal>
    );
}

export default CreateCylinderModal;
