import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Divider, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";

import NavItem from "../nav_item";

const Sidebar = ({ drawerWidth }: { drawerWidth: number }) => {

    const menuEntries = [
        // { name: "Dashboard", path: "/Dashboard", iconName: "dashboard" },
        { name: "Wallet", path: "/Wallet", iconName: "account_balance_wallet" },
        { name: "Transactions", path: "/Transactions", iconName: "sync_alt"},
        { name: "Certificates", path: "/Certificates", iconName: "fact_check"}
        //{ name: "Transportation", path: "/Transportation", iconName: "sync_alt" },
        //{ name: "Maintenance", path: "/Maintenance", iconName: "construction" },
    ];

    const otherEntries = [
        { name: "Help", path: "/Help", iconName: "help" },
        { name: "Settings", path: "/Settings", iconName: "settings" },
    ];

    const { user, logout } = useAuth0();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar>
                {user !== undefined ?
                    (<>
                        <Avatar src={user.picture}>{user.name?.charAt(0) ?? "U"}</Avatar>
                        <Typography sx={{ marginLeft: "1rem" }} variant="h6">{user.name}</Typography>
                    </>)
                    :
                    (<></>)}
            </Toolbar>

            <Divider />
            <List>
                {menuEntries.map(({ name, path, iconName }, index) => (
                    <NavItem key={index} name={name} path={path} iconName={iconName} />
                ))}
            </List>
            <Divider />
            <List>
                {otherEntries.map(({ name, path, iconName }, index) => (
                    <NavItem key={index} name={name} path={path} iconName={iconName} />
                ))}
                <ListItemButton onClick={() => logout()} key={otherEntries.length}>
                    <ListItemIcon>
                        <Icon>logout</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItemButton>
            </List>

        </Drawer>
    );
}

export default Sidebar;
