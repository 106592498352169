import { GetAllCylinderTransactions } from "@pharox/api-package/lib";
import Layout from "../components/layout";
import { useQuery } from "react-query";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";

const Transaction = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [ cylinderID, setCylinderID ] = useState("");
    const { isLoading, data, refetch } = useQuery({
        queryKey: ['transactions', cylinderID], 
        queryFn: async () => {
            var token = await getAccessTokenSilently();
            var result = await GetAllCylinderTransactions({token: token, cylinderID: cylinderID});
            return result;
        },
        enabled: cylinderID !== "",
    });

    const columns: GridColDef[] = [
        { field: 'CylinderID', headerName: 'CylinderID', width: 200},
        { field: 'TransactionID', headerName: 'TransactionID', width: 200},
        { field: 'Created', headerName: 'Created', width: 180},
        { field: 'Type', headerName: 'Type'},
        { field: 'Description', headerName: 'Description'},
        { field: 'NonConformityCode', headerName: 'NonConformityCode', width: 160},
    ];

    return (
        <Layout name={"Transactions"}>
            <Typography variant="h6" sx={{marginBottom: '1rem'}}>Enter a cylinder ID and view the transactions</Typography>
            <TextField sx={{marginBottom: '1rem'}} onChange={(e) => setCylinderID(e.target.value)} value={cylinderID} label="CylinderID" variant="outlined"></TextField>
            <DataGrid
                    rows={data?.results ?? []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={(data) => data['CylinderID']}
                    loading={isLoading}
            />
        </Layout>
    )
}

export default Transaction;

