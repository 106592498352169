import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Layout from "./layout";
import { Loader } from "./Loader";
//import { PageLoader } from "./page-loader";

export const ProtectedRoute = ({ component }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Layout>
        <Loader />
      </Layout>
    ),
  });

  return <Component />;
};