import { ListItemButton, ListItemIcon, Icon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface INavItemProps {
    name: string;
    path: string;
    iconName: string;
}

const NavItem: React.FC<INavItemProps> = ({
    name,
    path,
    iconName
}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const selected = location.pathname === `${path}`;

    return (
        <ListItemButton selected={selected} onClick={() => navigate(path)}>
            <ListItemIcon>
                <Icon color={selected ? 'primary' : undefined}>{iconName}</Icon>
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItemButton>
    )
}

export default NavItem;