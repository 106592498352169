import Layout from "../components/layout";

const Maintenance = () => {
    return (
        <Layout name={"Maintenance"}>
        </Layout>
    )
}

export default Maintenance;

