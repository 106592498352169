import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { CylinderStatusEnum } from "@pharox/api-package/lib";

interface IStatusSelect {
    label: string;
    value: CylinderStatusEnum;
    onChange: (event: SelectChangeEvent<CylinderStatusEnum>, child: React.ReactNode) => void;
}

const StatusSelect: React.FC<IStatusSelect> = ({
    label,
    value,
    onChange
}) => {

    return (
        <FormControl fullWidth>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            value={value}
                            label={label}
                            onChange={onChange}
                        >
                            <MenuItem value={CylinderStatusEnum.EMPTY}>Empty</MenuItem>
                            <MenuItem value={CylinderStatusEnum.FILLED}>Filled</MenuItem>
                            <MenuItem value={CylinderStatusEnum.SCRAPPED}>Scrapped</MenuItem>
                        </Select>
        </FormControl>
    );
};

export default StatusSelect;
