// import { getSession, withPageAuthRequired } from "@auth0/nextjs-auth0";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Toolbar } from "@mui/material";
import { GetAllCylinders } from "@pharox/api-package/lib";
import { useQuery } from "react-query";
import CylinderList from "../components/CylinderList";
import { DashboardBar } from "../components/DashboardBar";
import Layout from "../components/layout";

const Wallet = (props: any) => {
    const { getAccessTokenSilently } = useAuth0();
    const { isLoading, data } = useQuery({
        queryKey: 'cylinders', 
        queryFn: async () => {
            var token = await getAccessTokenSilently();
            var result = await GetAllCylinders({token: token});
            return result;
        },
    });

    return (
        <Layout name={"Wallet"}>
            <DashboardBar/>
            <Typography variant="h4" sx={{paddingTop: '2rem'}}>Cylinder Management</Typography>
            <Typography variant="h6">Manage all your owned cylinders</Typography>
            
            <CylinderList loading={isLoading} list={data}/>   
        </Layout>
    )
}

export default Wallet;
