import { BASE_URL, GetAllCylinderCertificates, GetAllCylinderTransactions } from "@pharox/api-package/lib";
import Layout from "../components/layout";
import { useQuery } from "react-query";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, IconButton, Link, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';
import { DashboardBar } from "../components/DashboardBar";

const Certificates = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [ cylinderID, setCylinderID ] = useState("");
    const { isLoading, data } = useQuery({
        queryKey: ['certificates', cylinderID], 
        queryFn: async () => {
            var token = await getAccessTokenSilently();
            var result = await GetAllCylinderCertificates({token: token, cylinderID: cylinderID});
            return result;
        },
        enabled: cylinderID !== "",
        // TODO should remove later
        // placeholderData: { data: [{
        //     Created: new Date().toISOString(),
        //     Location: "https://cloud.broodrooster.dev/s/5FENpxXydwJXnkp",
        //     Hash: "408f31d86c6bf4a8aff4ea682ad002278f8cb39dc5f37b53d343e63a61f3cc4f"
        // }]},
    });

    const columns: GridColDef[] = [
        { field: 'CylinderID', headerName: 'CylinderID', width: 200},
        { field: 'CertificateID', headerName: 'CertificateID', width: 200},
        { field: 'Created', headerName: 'Created', width: 200},
        { field: 'Hash', headerName: 'Hash', width: 400},
        { field: 'Location', headerName: 'Location', width: 400},
        {
            field: 'Download',
            headerName: 'Download',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                
              return <IconButton color="primary" href={BASE_URL + params.row.Location} target="_blank" rel="noopener noreferrer"><DownloadIcon/></IconButton>;
            },
        },
    ];

    return (
        <Layout name={"Certificates"}>
            <Typography variant="h6" sx={{marginBottom: '1rem'}}>Enter a cylinder ID and view the certificates</Typography>
            <TextField sx={{marginBottom: '1rem'}} onChange={(e) => setCylinderID(e.target.value)} value={cylinderID} label="CylinderID" variant="outlined"></TextField>
            <DataGrid
                    rows={data?.results ?? []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={(data) => data['CertificateID']}
                    loading={isLoading}
            />
        </Layout>
    )
}

export default Certificates;

