import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react"
import Sidebar from "../Sidebar"
import DashboardBarIndicator from "../dashboardBarIndicator";

export default function Layout({ children, name }: any) {
    const drawerWidth: number = 240;
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        {name ?? "Pharox Blockchain"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Sidebar drawerWidth={drawerWidth} />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />

                {children}
            </Box>
        </Box>
    )
}