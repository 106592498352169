import Layout from "../components/layout";

const Settings = () => {
    return (
        <Layout name={"Settings"}>
            
        </Layout>
    )
}

export default Settings;

// export const getServerSideProps = withPageAuthRequired();