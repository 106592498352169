// import { withPageAuthRequired } from '@auth0/nextjs-auth0';

import Layout from "../components/layout";

const Help = () => {
    return (
        <Layout name={"Help"}>
            
        </Layout>
    )
}

export default Help;

// export const getServerSideProps = withPageAuthRequired();