import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
interface IDashboardBarIndicatorProps {
    total: Number,
    head1 : string,
    loading: boolean
}

export default function DashboardBarIndicator(props: IDashboardBarIndicatorProps){
    return (
      <Card sx={{ maxWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {props.head1}
          </Typography>
          <Typography variant="h5">
            {props.loading ? <Skeleton /> : props.total.toString()}
          </Typography>
        </CardContent>
      </Card>
    );
}
