import React from "react";

interface ITransactionListItem {
    TrNo: string;
    SiNo: string
    TrDate: string;
    TrStatus: string;
}

const Transportation = (props: any) => {
    const [search, setSearch] = React.useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }

    // Handleclick for the action buttons 
    // const handleClick = (TrNo: string) => {
    //     console.log(TrNo);
    // }

    const List = props.page.data;

    return (
        <div className="tranactionManagement">
            <div className="topHeader">
                <div className="header">
                    <h2>Transaction management</h2>
                    <p>View all cylinder transactions</p>
                </div>
                <input className="searchBar" placeholder="Search by id" onChange={(e) => handleChange(e)} value={search}></input>
            </div>

            <div className="transactionList">
                <div className="transactionListHeader">
                    <p>TrNo</p>
                    <p>SiNo</p>
                    <p>Transaction Date</p>
                    <p>Transaction status</p>
                    <p>Action</p>
                </div>
                {/* <TransactionList search={search} /> */}
                {List.filter((x: ITransactionListItem) =>
                    search && search !== "" ? x.TrNo?.includes(search) || x.SiNo?.includes(search) : true
                )
                    .map((item: ITransactionListItem, index: number) => {
                        var add = "";
                        if (index % 2 === 0)
                            add = "even";
                        return (
                            <div className={"ListItem " + add} key={index}>
                                <p>{item.TrNo}</p>
                                <p>{item.SiNo}</p>
                                <p>{item.TrDate}</p>
                                <p className="TrStatus">{item.TrStatus}</p>
                                {/* Action button preparation */}
                                {/* <div className="PenImageWrapper">
                                    <Image alt="PenImage" className="penIcon" src={"/Pen_Icon.svg"} onClick={() => handleClick(item.TrNo)} height={20} width={20} />
                                </div> */}
                            </div>
                        )
                    })
                }
                <div className="Pagination"></div>
            </div>
        </div>
    )
}

export default Transportation;

// export const getServerSideProps = withPageAuthRequired({
//     async getServerSideProps(ctx) {
//         // access the user session
//         const session = getSession(ctx.req, ctx.res);
//         const res = await fetch(`http://localhost:3000/api/transportation/transactionList/`)

//         const data: ITransactionList = await res.json();

//         return {
//             props: { page: data }
//         }
//     }
// });