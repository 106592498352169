import React from "react";
import Layout from "../components/layout";

import { Loader } from "../components/Loader";

export const CallbackPage = () => {
  return (
    <Layout>
      <Loader />
    </Layout>
  )
};