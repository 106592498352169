import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout";
import Index from "./pages/indexPage";
import Help from "./pages/HelpPage";
import Maintenance from "./pages/MaintenancePage";
import Settings from "./pages/SettingsPage";
import Wallet from "./pages/WalletPage";
import { CallbackPage } from "./pages/CallbackPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Transportation from "./pages/TransportationPage";
import { useAuth0 } from "@auth0/auth0-react";
import { Backdrop, CircularProgress } from "@mui/material";
import Transaction from "./pages/TransactionPage";
import Certificates from "./pages/CertificatesPage";

export default function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <Layout>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Layout>
    );
  }

  return (
    <>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route index element={isAuthenticated ? <Navigate to="/Wallet" replace={true} /> : <Index />} />
        <Route path="/callback" element={<CallbackPage />} />

        <Route
          path="/Help"
          element={<ProtectedRoute component={Help} />}
        />

        <Route
          path="/Maintenance"
          element={<ProtectedRoute component={Maintenance} />}
        />

        <Route
          path="/Settings"
          element={<ProtectedRoute component={Settings} />}
        />

        <Route
          path="/Transportation"
          element={<ProtectedRoute component={Transportation} />}
        />

        <Route
          path="/Transactions"
          element={<ProtectedRoute component={Transaction} />}
        />

        <Route
          path="/Certificates"
          element={<ProtectedRoute component={Certificates} />}
        />

        <Route
          path="/Wallet"
          element={<ProtectedRoute component={Wallet} />}
        />
      </Routes>
    </>
  );
}
