import React from 'react';
import { Dialog, TextField, Button, Box, Alert, Typography, Stack } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Cylinder, CylinderStatusEnum, GasTypeEnum, CreateTransfer } from '@pharox/api-package/lib';
import { useAuth0 } from '@auth0/auth0-react';
import ActionModal from './ActionModal';


interface ISendCylinderModal {
    setOpen: (x: any) => void;
    cylinder: Cylinder;
}

const SendCylinderModal: React.FC<ISendCylinderModal> = ({
    setOpen,
    cylinder
}) => {
    const [barcode, setBarcode] = React.useState(cylinder.Barcode);
    const [newOwner, setNewOwner] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [nonConformityCode, setNonConformityCode] = React.useState("");
    
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const { mutateAsync, data, isSuccess, error, isError, isLoading} = useMutation(CreateTransfer, {
        onSuccess: () => {
            queryClient.invalidateQueries('cylinders')
        },
    });

    const handleClick = async () => {
        const token = await getAccessTokenSilently();
        await mutateAsync({
            token: token,
            input: {
                Barcode: barcode,
                Description: description,
                NonConformityCode: nonConformityCode
            }, 
            newOwnerID: newOwner
        });
    }

    return (
        <ActionModal
            setOpen={setOpen}
            handleClick={handleClick}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error?.toString() ?? "Unknown"}
            success={data?.message ?? "Success"}
            actionText={"Send"}
            showAction={!isSuccess}
        >
                <Typography variant="h6">Transfer Cylinder</Typography>
                <TextField value={newOwner} onChange={(e) => setNewOwner(e.target.value)} fullWidth label="New owner" variant="outlined" />
                <TextField value={barcode} onChange={(e) => setBarcode(e.target.value)} fullWidth label="Barcode" variant="outlined" />
                <TextField value={description} onChange={(e) => setDescription(e.target.value)} fullWidth label="Description" variant="outlined" />
                <TextField value={nonConformityCode} onChange={(e) => setNonConformityCode(e.target.value)} fullWidth label="NonConformityCode" variant="outlined" />
        </ActionModal>
    );
}

export default SendCylinderModal;
