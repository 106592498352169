import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { GasTypeEnum } from "@pharox/api-package/lib";

interface IGasTypeSelect {
    label: string;
    value: GasTypeEnum;
    onChange: (event: SelectChangeEvent<GasTypeEnum>, child: React.ReactNode) => void;
}

const GasTypeSelect: React.FC<IGasTypeSelect> = ({
    label,
    value,
    onChange
}) => {

    return (
        <FormControl fullWidth>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            value={value}
                            label={label}
                            onChange={onChange}
                        >
                            {
                                Object.values(GasTypeEnum).map((type) => (
                                    <MenuItem value={type}>{type.toString()}</MenuItem>
                                ))
                            }
                        </Select>
        </FormControl>
    );
};

export default GasTypeSelect;
