import React, { useState } from "react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PassportModal from "./PassportModal";
import SendCylinderModal from "./SendCylinderModal";
import { CylinderList, Cylinder } from "@pharox/api-package/lib/index";
import { Button, IconButton, LinearProgress } from "@mui/material";
import CreateCylinderModal from "./CreateCylinderModal";
import AddCertificateModal from "./AddCertificateModal";

enum cylinderlistclick {
    Passport,
    Send,
    Certificate
}

interface ICylinderList {
    list: CylinderList | undefined,
    loading: boolean
}

const CylinderTable: React.FC<ICylinderList> = ({
    list,
    loading
}) => {
    const [cylinderPassportModal, setcylinderPassportModal] = useState(false);
    const [cylinderSendModal, setcylinderSendModal] = useState(false);
    const [cylinderCreateModal, setcylinderCreateModal] = useState(false);
    const [cylinderCertificateModal, setcylinderCertificateModal] = useState(false);
    const [cylinder, setCylinder] = useState<Cylinder>();

    const handleClick = (action: cylinderlistclick, cylinder: Cylinder) => {

        setCylinder(cylinder);
        if (action === cylinderlistclick.Passport) {
            setcylinderPassportModal(true);
        }

        if (action === cylinderlistclick.Send) {
            setcylinderSendModal(true);
        }

        if (action === cylinderlistclick.Certificate) {
            setcylinderCertificateModal(true);
        }
    }

    const columns: GridColDef[] = [
        { field: 'CylinderID', headerName: 'CylinderID', width: 150},
        { field: 'SiNo', headerName: 'CylinderNumber', width: 120},
        { field: 'Barcode', headerName: 'Barcode', width: 70},
        { field: 'PartNumber', headerName: 'PartNumber'},
        { field: 'Description', headerName: 'Description'},
        { field: 'Status', headerName: 'Status'},
        { field: 'GasType', headerName: 'GasType'},
        { field: 'Size', headerName: 'Size', width: 50},
        { field: 'Owner', headerName: 'Owner'},
        { field: 'ProductionDate', headerName: 'ProductionDate', width: 120},
        { field: 'Value', headerName: 'Value', width: 40},
        { field: 'Manufacturer', headerName: 'Manufacturer'},
        {
            field: 'Edit',
            headerName: 'Edit',
            sortable: false,
            width: 30,
            renderCell: (params) => {
              const onClick = (e: any) => {
                e.stopPropagation(); // don't select this row after clicking
                handleClick(cylinderlistclick.Passport, params.row)
              };
        
              return <IconButton color={"primary"} onClick={onClick}><EditIcon/></IconButton>;
            },
        },
        {
            field: 'Transfer',
            headerName: 'Transfer',
            sortable: false,
            width: 50,
            renderCell: (params) => {
              const onClick = (e: any) => {
                e.stopPropagation(); // don't select this row after clicking
                handleClick(cylinderlistclick.Send, params.row)
              };
        
              return <IconButton color={"primary"} onClick={onClick}><SendIcon/></IconButton>;
            },
        },
        {
            field: 'Certificate',
            headerName: 'Certificate',
            sortable: false,
            width: 50,
            renderCell: (params) => {
              const onClick = (e: any) => {
                e.stopPropagation(); // don't select this row after clicking
                handleClick(cylinderlistclick.Certificate, params.row)
              };
        
              return <IconButton color={"primary"} onClick={onClick}><NoteAddIcon/></IconButton>;
            },
        },
    ];

    return (
        <>
            {cylinderPassportModal ? <PassportModal cylinder={cylinder!} setOpen={setcylinderPassportModal} /> : undefined}
            {cylinderSendModal ? <SendCylinderModal cylinder={cylinder!} setOpen={setcylinderSendModal} /> : undefined}
            {cylinderCertificateModal ? <AddCertificateModal cylinder={cylinder!} setOpen={setcylinderCertificateModal} /> : undefined}
            {cylinderCreateModal ? <CreateCylinderModal setOpen={setcylinderCreateModal} /> : undefined}
            

            <Button variant={'contained'} sx={{marginTop: '1rem'}} onClick={() => setcylinderCreateModal(true)}>Add Cylinder</Button>
            
            <div style={{ height: '100%', width: '100%', marginTop: '1rem' }}>
                <DataGrid
                    rows={list?.results ?? []}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={(data) => data['CylinderID']}
                    loading={loading}
                />
            </div>
        </>
    );
}

export default CylinderTable;

