import React from 'react';
import { Dialog, TextField, Button, Box, Alert, Typography, Stack, Avatar, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Cylinder, CylinderStatusEnum, GasTypeEnum, CreateTransfer, AddCertificate } from '@pharox/api-package/lib';
import { useAuth0 } from '@auth0/auth0-react';
import ActionModal from './ActionModal';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import prettyBytes from 'pretty-bytes';


interface IAddCertificateModal {
    setOpen: (x: any) => void;
    cylinder: Cylinder;
}

const AddCertificateModal: React.FC<IAddCertificateModal> = ({
    setOpen,
    cylinder
}) => {
    
    const [cylinderID, setCylinderID] = React.useState(cylinder.CylinderID);
    const [file, setFile] = React.useState<File | undefined>(undefined);

    
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const { mutateAsync, data, isSuccess, error, isError, isLoading} = useMutation(AddCertificate, {
        onSuccess: async () => {
           await queryClient.invalidateQueries(['certificates', cylinderID]);
        }
    });

    const handleClick = async () => {
        const token = await getAccessTokenSilently();
        await mutateAsync({
            token: token,
            file: file!,
            cylinderID: cylinderID
        });
    }

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setFile(event.currentTarget.files?.item(0) ?? undefined);
        event.preventDefault();
    }

    const handleDelete = () => {
        setFile(undefined);
    }

    return (
        <ActionModal
            setOpen={setOpen}
            handleClick={handleClick}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error?.toString() ?? "Unknown"}
            success={data?.message ?? "Success"}
            actionText={"Upload"}
            showAction={!isSuccess && file !== undefined}
        >
                <Typography variant="h6">Add Certificate</Typography>
                <TextField disabled value={cylinderID} onChange={(e) => setCylinderID(e.target.value)} fullWidth label="CylinderID" variant="outlined" />
                {file !== undefined ? <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.name}
                    secondary={prettyBytes(file.size)}
                  />
                </ListItem> : undefined}
                <Button disabled={file !== undefined} variant="contained" component="label">
                    Add file
                    <input id="file-input" hidden accept="application/pdf" type="file" onChange={onChangeFile}/>
                </Button>
        </ActionModal>
    );
}

export default AddCertificateModal;
