import { Dialog, Stack, Typography, TextField, Alert, Button } from "@mui/material";
import React from "react";

interface IActionModal {
    setOpen: (x: any) => void;
    handleClick: () => void;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: string;
    success: string;
    actionText: string;
    showAction: boolean;
    children?: React.ReactNode;
}

const ActionModal: React.FC<IActionModal> = ({
    setOpen,
    handleClick,
    isLoading,
    isSuccess,
    isError,
    error,
    success,
    actionText,
    showAction,
    children
}) => {

    return (
        <Dialog open={true} fullWidth>
            <Stack spacing={2} sx={{padding: "2rem"}}>
                {children}
                {isLoading ? <Alert severity="info">Loading...</Alert> : undefined}
                {isSuccess ? <Alert severity="success">{success}</Alert> : undefined}
                {isError ? <Alert severity="error">An error occurred: {error?.toString() ?? "Unknown"}</Alert> : undefined}
                <Stack direction="row">
                    <Button disabled={!showAction} onClick={() => handleClick()}>{actionText}</Button>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}

export default ActionModal;
